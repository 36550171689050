import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/itemKpr'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  getAll () {
    return httpClient.get(`${endpoint}/all`)
  },

  getAllWithDetails () {
    return httpClient.get(`${endpoint}/allWithDetails`)
  },

  getDetailsByBank (idBankKpr) {
    return httpClient.get(`${endpoint}/detailsByBank/${idBankKpr}`)
  },

  saveD (params) {
    return httpClient.post(`${endpoint}/saveD`, params)
  }
}
